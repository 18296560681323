/* website: 4296-wheatonkia
 * created at 2024-01-09 15:30 by Mfaye
 */

// Import all makes and organization styles files
@import "../templates/makes/kia.scss";
@import "../utils/icons.scss";
.widget-sr{
  &.makes__kia{
    &.dealer__wheatonkia-681{
      .listing-tile .listing-tile-image .car-image-wrapper{
        height: auto;
      }
    }
  }
}